import { composeEventData, namespace, property } from '../factories';

export type DayOfWeek =
  | 'sunday'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday';

export type Data = {
  adobeVersion?: string;
  appSessionId?: string;
  callId?: string;
  dayOfWeek?: DayOfWeek;
  hourOfDay?: number;
  id?: string | null;
  isPlaying?: 'false' | 'true';
  querystring?: { [key: string]: string };
  reportedDate?: number;
  screenOrientation?: string;
  sequenceNumber?: number;
  sessionNumber?: number;
  timezone?: string;
  userAgent?: string;
  visitorId?: string | null;
  volume?: number;
};

export type GlobalData = {
  global: {
    device: {
      adobeVersion?: string;
      appSessionId?: string;
      callId?: string;
      dayOfWeek?: DayOfWeek;
      hourOfDay?: number;
      id?: string | null;
      isPlaying?: 'false' | 'true';
      reportedDate?: number;
      screenOrientation?: string;
      sessionNumber?: number;
      timezone?: string;
      userAgent?: string;
      volume?: number;
    };
    querystring: {
      [key: string]: string;
    };
    session: {
      sequenceNumber?: string;
    };
    user: {
      visitorId?: string | null;
    };
  };
};

function globalData(data: Data): GlobalData {
  return composeEventData('global')(
    namespace('global')(
      namespace('device')(
        property('adobeVersion', data.adobeVersion),
        property('appSessionId', data.appSessionId),
        property('callId', data.callId),
        property('dayOfWeek', data.dayOfWeek),
        property('hourOfDay', data.hourOfDay),
        property('id', data.id),
        property('isPlaying', data.isPlaying),
        property('reportedDate', data.reportedDate),
        property('screenOrientation', data.screenOrientation),
        property('sessionNumber', data.sessionNumber),
        property('timezone', data.timezone),
        property('userAgent', data.userAgent),
        property('volume', data.volume),
      ),
      property('querystring', data.querystring),
      namespace('session')(property('sequenceNumber', data.sequenceNumber)),
      namespace('user')(property('visitorId', data.visitorId)),
    ),
  ) as GlobalData;
}

export default globalData;
