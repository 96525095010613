import cookie from 'js-cookie';
import DIRECT_CALL_RULES from './constants/directCallRules';
import Events from './constants/events';
import getScript from 'utils/getScript';
import globalData, {
  DayOfWeek,
  Data as GlobalData,
} from './helpers/globalData';
import logger, { CONTEXTS } from 'modules/Logger';
import Queue from './Queue';
import { get, merge, set } from 'lodash-es';
import { parse } from 'qs';
import { v4 as uuid } from 'uuid';
import { waitForDocumentLoaded } from '@iheartradio/web.signal';
import type { DataLayer, EventName } from './types';
import type {
  InAppMessageExitData,
  InAppMessageOpenData,
} from './helpers/inAppMessage';
import type { Data as ItemSelectedData } from './helpers/itemSelected';
import type { Data as OpenCloseData } from './helpers/openClose';
import type { Data as PageViewData } from './helpers/pageView';
import type { Data as PasswordData } from './helpers/password';
import type { Data as PaymentData } from './helpers/payment';
import type { RegGate as RegGateData } from './helpers/regGate';
import type { Data as SaveDeleteData } from './helpers/saveDelete';
import type { Data as ShareData } from './helpers/share';
import type { Data as ShuffleData } from './helpers/shuffle';
import type { Data as StationData } from './helpers/station';
import type { Data as ThumbingData } from './helpers/thumbing';
import type { Data as UpsellData } from './helpers/upsell';

type Config = {
  onTrack: (
    action: EventName,
    event: any,
    dataLayer: DataLayer,
  ) => Promise<void> | void;
  timeout: number;
};

type TrackData = ((a: DataLayer) => Record<string, any>) | Record<string, any>;

/**
 * Combination of a `track` call's parameters.
 * Used by the queue mechanism to store track calls while waiting for `window._satellite`
 */
interface TrackItem {
  action: EventName;
  data: TrackData;
}

type TrackAction =
  | typeof DIRECT_CALL_RULES.TRACK_PAGE
  | typeof DIRECT_CALL_RULES.TRACK_ACTION;

/**
 * _satellite internal representation of a tracck call. Used to monitor track calls in `setupMonitors`.
 * A lot of these items are optional so don't rely on them too heavily.
 * It is advised to use a getter like lodash.get to access these fields.
 */
interface TrackEvent {
  condition?: any;
  rule: {
    id: string;
    name: string;
    events: Array<{
      modulePath: string;
      settings: { identifier: string };
    }>;
    conditions: Array<any>;
    actions: Array<{
      modulePath: string;
      settings: {
        customSetup?: { source: any };
        isExternal?: boolean;
        language?: string;
        source?: string;
        trackerProperties?: {
          eVars: Array<{ name: string; type: string; value: string }>;
          pageName: string;
          props: Array<{ name: string; type: string; value: string }>;
        };
      };
    }>;
  };
}

/**
 * Because we do not initialize this class server-side, many class properties and methods are optional.
 *
 * TODO Explore why this class does not get initialized server-side so we can improve these typings.
 * Could potentially make all the module functions noops on the server which would allow us to be
 * more strict with the types.
 */

class Analytics {
  afterDequeue?: Promise<void>;

  isLoaded = false;

  isReady = false;

  isTracking = false;

  onTrack?: Config['onTrack'];

  queue: Queue<TrackItem> = new Queue<TrackItem>();

  requestId: number | null = null;

  timeout?: Config['timeout'];

  timeoutId: number | null = null;

  setGlobalData?: (a: GlobalData) => void;

  trackClick?: (a: string) => Promise<void>;

  trackCreateContent?: (a: StationData) => Promise<void>;

  trackInAppMessageExit?: (a: InAppMessageExitData) => Promise<void>;

  trackInAppMessageOpen?: (a: InAppMessageOpenData) => Promise<void>;

  trackItemSelected?: (a: ItemSelectedData) => Promise<void>;

  trackOpenClosePlayer?: (a: OpenCloseData) => Promise<void>;

  trackPageView?: (a: PageViewData) => Promise<void>;

  trackPassword?: (a: PasswordData) => Promise<void>;

  trackPaymentExit?: (a: { exitType: string }) => Promise<void>;

  trackPaymentOpen?: (a: PaymentData) => Promise<void>;

  trackRegGateExit?: (a: string) => Promise<void>;

  trackRegGateOpen?: (a: RegGateData) => Promise<void>;

  trackSaveDelete?: (a: SaveDeleteData) => Promise<void>;

  trackShare?: (a: ShareData) => Promise<void>;

  trackShuffle?: (a: ShuffleData) => Promise<void>;

  trackThumbing?: (a: ThumbingData) => Promise<void>;

  trackUpsellExit?: (a: {
    destination: string;
    exitType: string;
    campaign?: string;
  }) => Promise<void>;

  trackUpsellOpen?: (a: UpsellData) => Promise<void>;

  constructor(config: Config) {
    if (!__CLIENT__) return;
    this.onTrack = config.onTrack;
    this.timeout = config.timeout;
    this.afterDequeue = this.load();
  }

  /**
   * We need to clear certain namespaces between each event. This api allows us to do so and replace
   * the namespace with any value see fit.
   */
  clear(path: Array<string>, value: any = {}) {
    set(window.analyticsData, path, value);
  }

  static create(config: Config): Analytics {
    return new Analytics(config);
  }

  /**
   * We implemented an event queue for a couple of reasons: sometimes we fire off events before we
   * have resolved the third-party Adobe scripts we depend on and sometimes events fire very closely
   * to each other time-wise. Because of DTM's limitations, events that are fired off closely to
   * each other tend to collide and cache each other's data. If we are not ready to fire an event or
   * we are already currently within the tracking flow, then we will queue the event and flush it
   * once the above scenarios are no longer true.
   */
  async dequeue(): Promise<void> {
    const trackItem = this.queue.dequeue();
    if (!trackItem) return;
    await this.track(trackItem.action, trackItem.data);
  }

  /**
   * In the event we need to reach into window.analyticsData, we use this method to do so.
   */
  get(path?: Array<string> | null | undefined, fallback?: any) {
    if (!path) return window.analyticsData;
    return get(window.analyticsData, path, fallback);
  }

  /**
   * If we fail to resolve the proper third party global variables that we depend on to fire events,
   * then we handle this by logging out an error and setting the prototype of this module to noops.
   */
  handleOnError = () => {
    if (this.requestId !== null) window.cancelAnimationFrame(this.requestId);
    const properties = Object.getOwnPropertyNames(Analytics.prototype);
    const noops = properties.reduce(
      (acc, curr) => ({ ...acc, [curr]: () => Promise.resolve() }),
      {},
    );
    Object.setPrototypeOf(this, noops);
    throw new Error(`Adobe's analytics library failed to load.`);
  };

  /**
   * When we initialize this module, we call this method to make sure initial global data and
   * variables are in place.
   */
  async load(): Promise<void> {
    await waitForDocumentLoaded();

    if (window.analyticsData?.config?.dtmUrl)
      getScript(window.analyticsData.config.dtmUrl);

    /**
     * We set this timeout in case the third party Adobe libraries fail to load. We wait the length
     * of TIMEOUT_DURATION and then replace the prototype with noops.
     */
    this.timeoutId = window.setTimeout(this.handleOnError, this.timeout);

    /**
     * This is the first global variable the we have to wait for. Without it, we are unable to set
     * critical pieces of global data.
     */
    await this.waitForGlobal('_satellite');

    // After global _satellite is loaded, set up monitoring functions
    this.setupMonitors();

    /** Refer to method definition for usage. */
    this.removeDataElementCaches();

    const { getVisitorId, visitorSessionCount } = window._satellite;
    const visitor = getVisitorId() || {};
    const date = new Date();

    const timezone = new Intl.DateTimeFormat(undefined, {
      timeZoneName: 'short',
    })
      .formatToParts()
      .find(part => part.type === 'timeZoneName')?.value;

    let visitorId = null;

    try {
      visitorId = visitor.getMarketingCloudVisitorID();
    } catch (error) {
      // We don't want to do anything if it fails to load. Just allow `visitorId` to be null.
    }

    // while it's redundant to include deviceId in the globalData payload below as getDeviceId()
    // also sets the value of deviceID in globalData, for clarity's sake it is included
    const deviceId = this.getDeviceId();

    /**
     * Here we are setting some global data that is required for every analytics call we fire.
     */
    this.set(
      globalData({
        adobeVersion: window?.tracker?.version,
        appSessionId: uuid(),
        dayOfWeek: (
          [
            'sunday',
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
          ] as Array<DayOfWeek>
        )[date.getDay()],
        hourOfDay: date.getHours(),
        id: deviceId,
        isPlaying: 'false',
        querystring: parse(window.location.search, { ignoreQueryPrefix: true }),
        reportedDate: date.getTime(),
        screenOrientation: window.matchMedia('(orientation: portrait)').matches
          ? 'portrait'
          : 'landscape',
        sessionNumber: visitorSessionCount && visitorSessionCount(),
        timezone,
        userAgent: window.navigator.userAgent,
        visitorId,
      }),
    );

    /**
     * This is the second global variable the we have to wait for. Without it, we can't send any
     * event data to Adobe.
     */
    await this.waitForGlobal('tracker');

    /**
     * If both of the aformentioned global variables have resolved, we can then clear the timeout we
     * set above at the beginning of this method.
     */
    window.clearTimeout(this.timeoutId);

    /**
     * Once we have loaded, we can now flush the queue of any events that have already fired.
     */
    this.isLoaded = true;

    await this.dequeue();
  }

  /**
   * Adobe DTM was never meant for single page applications. As a result, they cache data element
   * values between page refreshes. Since we are never refreshing the page, we need to remove their
   * underlying caching mechanism completetly. Otherwise, stale data will persist through subsequent
   * calls.
   */
  removeDataElementCaches(): void {
    if (!window._satellite.dataElements) return;
    Object.keys(window._satellite.dataElements)
      .map(key => window._satellite.dataElements[key])
      .forEach(dataElement => {
        /* eslint-disable-next-line no-param-reassign */
        delete dataElement.storeLength;
      });
  }

  /**
   * We use this api to set global data within window.analyticsData. This global data is merged into
   * each event that is fired off to Adobe.
   */
  set(data: Record<string, any> = {}): void {
    const { events = {}, global = {}, ...rest } = data;
    merge(window.analyticsData, { events, global }, { global: rest });
  }

  /**
   * getDeviceId defined separately here to keep analytics & a/b testing in sync:
   * a/b testing may ask for value before analytics would have determined it in load()
   */
  getDeviceId(): string {
    let deviceId = this.get(['global', 'device', 'id']);

    if (!deviceId) {
      deviceId = cookie.get('DEVICE_ID');
      if ([undefined, 'undefined'].includes(deviceId)) {
        deviceId = uuid();
        cookie.set('DEVICE_ID', deviceId, { path: '/' });
      }
      this.set(globalData({ id: deviceId }));
    }

    return deviceId;
  }

  /**
   * This method allows us to determine exactly when we wish to flush the queue. Some times there is
   * data we need to retrieve from amp before fire any events.
   */
  setReadyState(val = true): void {
    this.isReady = val;
  }

  /**
   * This is the most important method of the analytics. Most developers are either going to use
   * this method directly or an abstraction that sits on top of it. This method fires events off to
   * Adobe and Igloo, which is the essentially the entire point of this code.
   */
  async track(action: EventName, data: TrackData = {}): Promise<void> {
    const trackType: TrackAction =
      action === Events.PageView
        ? DIRECT_CALL_RULES.TRACK_PAGE
        : DIRECT_CALL_RULES.TRACK_ACTION;

    /**
     * If our dependent libraries have yet to load or if we are already tracking another event, we
     * push the current event onto the queue.
     */
    if (!this.isLoaded || this.isTracking) {
      this.queue.enqueue({ action, data });
      return this.afterDequeue;
    }

    this.isTracking = true;

    /**
     * In order to pass a pageName value with every track call (trackAction does not include it by default),
     * we must pull pageName from the last page_view call and include it manually.
     */
    const events = window.analyticsData?.events;

    const pageName =
      // Get pageName from active event if it exists
      events?.active?.pageName ??
      // Otherwise get pageName from last page_view event
      events?.page_view?.[(events?.page_view?.length ?? 0) - 1]?.pageName;

    const event = {
      pageName,
      ...(typeof data === 'function' ? data(window.analyticsData) : data),
    };

    /**
     * Again, Adobe DTM was not meant for single page apps, so between each event, we need to clear
     * intermediate caching.
     */
    this.clear(['events', 'active']);

    window.tracker.clearVars();

    /** We need to set dynamic global data that changes with each event. */
    this.set({
      event: {
        capturedTimestamp: Date.now(),
      },
      events: {
        [action]: [...this.get(['events', action], []), event],
        active: { action, ...event },
      },
      ...globalData({
        callId: uuid(),
        sequenceNumber:
          Number(this.get(['global', 'session', 'sequenceNumber'], 0)) + 1,
      }),
    });

    /** Finally, we call Adobe's underlying api to fire off events to them. */
    window._satellite.track(trackType);

    /**
     * Then we fire off a subsequent call to igloo, which is decoupled from this module in case
     * we need to add more side effects in the future. See this modules associated index.js file.
     */
    if (this.onTrack) {
      await this.onTrack(action, event, this.get());
    }

    this.isTracking = false;

    /**
     * Lastly, we empty the queue if there were any events that tried to fire while isTracking
     * was true.
     */
    return this.dequeue();
  }

  /**
   * We cannot offically load this module until external third-party libraries have resolved.
   * Namely, satelliteLib.js and s-code-contents.js. These are retrieved from a CDN that Adobe DTM
   * maintains. As a result, we need a mechanism to wait for certain global variables to resolve
   * against the global namespace (window). This utility enables us to asynchronously wait for
   * variables that subsequent analytics actions/events rely on.
   */
  async waitForGlobal(key: string): Promise<void> {
    const cb = (resolve: () => void): void => {
      if (!window[key as keyof Window] || !this.isReady) {
        this.requestId = window.requestAnimationFrame(cb.bind(this, resolve));
        return;
      }
      if (this.requestId !== null) window.cancelAnimationFrame(this.requestId);
      resolve();
    };

    return new Promise(cb.bind(this));
  }

  /**
   * This sets up some logging for any _satellite track calls.
   * Should only be used client side. Logs need to be enabled to see these messages.
   */
  setupMonitors(): void {
    if (__CLIENT__) {
      if (window._satellite) {
        window._satellite._monitors = window._satellite._monitors || [];

        window._satellite._monitors.push({
          ruleCompleted: (event: TrackEvent) => {
            logger.info(
              [CONTEXTS.ANALYTICS, `${get(event, ['rule', 'name'])} Completed`],
              {
                rule: get(event, 'rule'),
              },
            );
          },
          ruleConditionFailed: (event: TrackEvent) => {
            const errObj = new Error(
              `${get(event, ['rule', 'name'])} Condition Failed`,
            );
            logger.error(
              [CONTEXTS.ANALYTICS, errObj.message],
              errObj.message,
              { condition: event?.condition ?? '' },
              errObj,
            );
          },
          ruleTriggered: (event: TrackEvent) => {
            logger.info(
              [CONTEXTS.ANALYTICS, `${get(event, ['rule', 'name'])} Triggered`],
              {
                rule: get(event, 'rule'),
              },
            );
          },
        });
      }
    }
  }
}

export default Analytics;
