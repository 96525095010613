import { isString } from '@iheartradio/web.utilities';
import { www as translations } from '@iheartradio/web.translations';
import type { Replace } from 'type-fest';

type TranslationId = Exclude<keyof typeof translations, 'options'>;

type LanguageId = 'en' | 'en-US' | Replace<TranslationId, '_', '-'>;

// Translations made available via Transifex
// Filter out the "options" key which is generated by transifex automatically
const availableTranslations = Object.keys(translations).filter(
  key => key !== 'options',
) as Array<TranslationId>;

// Converts language identifiers like so: en_AU => en-AU
const translationIdToLanguageId = (s: TranslationId | LanguageId) =>
  s.replace('_', '-') as LanguageId;

// Converts language identifiers like so: en-AU => en_AU
const languageIdToTranslationId = (s: LanguageId | TranslationId) =>
  s.replace('-', '_') as TranslationId;

// We can declare the accepted languages based on the available translations instead of defining a static list
const acceptedLanguages = [
  // International English
  'en',
  // American English
  'en-US',
  // Languages with existing translations
  ...availableTranslations.map(translationIdToLanguageId),
];

// Determine if a given language string is one of our supported languages
export const isValidLanguage = (lang: string) =>
  acceptedLanguages.includes(lang);

/**
 * Returns a valid language string no matter what is passed in.
 *
 * If the passed lang is not valid, it will use the fallback.
 */
export const validateLanguage = (
  lang: unknown,
  fallback: LanguageId = 'en',
): LanguageId => {
  if (!isString(lang)) {
    return fallback;
  }

  return isValidLanguage(lang) ? (lang as LanguageId) : fallback;
};

/**
 * Returns a valid translateOverride no matter what is passed in.
 *
 * If the passed translateOverride is not valid, it will return `undefined`
 * and the translateOverride will have no effect.
 */
export const validateTranslateOverride = (translateOverride: unknown) => {
  return isString(translateOverride) ? translateOverride : undefined;
};

/**
 * Get the translations object for a given language string.
 */
export function getTranslations(
  lang?: LanguageId | TranslationId,
  override?: string,
): Record<string, string> {
  if (override) {
    // ZS 9/8/2017 fr is hardcoded, because we're really only after the keys
    return Object.keys(translations.fr).reduce(
      (result, key) => ({
        ...result,
        [key]: override.repeat(key.length),
      }),
      {},
    );
  }

  if (!lang) {
    return {};
  }

  const translationId = languageIdToTranslationId(lang);

  // Make sure the lang exists in the translations object
  return availableTranslations.includes(translationId)
    ? translations[translationId]
    : {};
}
