import { g as isFunction, e as isAsyncFunction, i as isUndefined } from './typeof-9343f9b0.esm.js';
import 'js-cookie';
import 'validator';
import 'just-omit';
import 'just-pick';
import 'just-kebab-case';
import 'just-capitalize';
import 'just-clamp';
import 'just-clone';
import 'just-compare';
import 'just-debounce-it';
import 'just-extend';
import 'just-filter-object';
import 'just-flush';
import 'just-memoize';
import 'just-once';
import 'just-prune';
import 'just-safe-set';
import 'just-shuffle';
import 'just-split';
import 'just-throttle';
import 'just-truncate';
import { v4 } from 'uuid';

/**
 * @remarks {@link createEmitter} provides a super flexible api for creating an asynchronous event
 * emitter. All events that are emitted through this api are fired off in the order in which they
 * are called while exposing each method as an aynchronous function.
 *
 * @param {Object} config - An object of functions. Also, optionally takes a special `initialize()`
 * function that will execute once before the first interaction with the emitter has resolved.
 *
 * @returns An object with the same functions you passed into the config object with the addition
 * of `subscribe()` and `initialized()` methods. The only difference is that all the methods you
 * passed into the config object are now asynchronous.
 *
 * @example
 *
 * const logger = createEmitter({
 *   error: message => ({
 *     level: 'error',
 *     message,
 *     timestamp: Date.now()
 *   }),
 * });
 *
 * logger.subscribe({
 *   error: ({ level, ...log }) => console[log.level](log),
 * });
 *
 * logger.error(':(');
 */

function createEmitter(config) {
  const INITIALIZE_KEY = 'initialize';
  const InitializeError = new Error(`${INITIALIZE_KEY}() can only be called once.`);
  const queue = [];
  const subscriptions = {};
  // const subscriptions: Subscriptions<T> = new WeakMap<symbol, Subscription<T>>();

  let enabled = true;
  let flushing = false;
  let initialized = false;
  async function dequeue() {
    if (queue.length === 0) {
      flushing = false;
      return;
    }
    const fn = queue.shift();
    await fn?.();
    dequeue();
  }
  function createMethod(key) {
    if (!isAsyncFunction(config[key])) {
      return function executeSyncMethod(...args) {
        try {
          if (key === INITIALIZE_KEY) {
            if (initialized) {
              throw InitializeError;
            } else {
              initialized = true;
            }
          } else {
            if (isUndefined(config.initialize)) {
              initialized = true;
            }
          }
          const fn = config[key];
          const result = fn(...args);
          if (enabled) {
            for (const symbol of Object.getOwnPropertySymbols(subscriptions)) {
              try {
                const subscription = subscriptions[symbol];
                subscription[key]?.(result, ...args);
                subscription.all?.(key, result, ...args);
              } catch {}
            }
          }
          return result;
        } catch (error) {
          for (const symbol of Object.getOwnPropertySymbols(subscriptions)) {
            try {
              const subscription = subscriptions[symbol];
              subscription.catch?.(key, error, ...args);
            } catch {}
          }
          throw error;
        }
      };
    }
    return async function enqueueAsyncMethod(...args) {
      return new Promise((resolve, reject) => {
        async function settle() {
          try {
            const fn = config[key];
            const result = await fn(...args);
            if (enabled) {
              for (const symbol of Object.getOwnPropertySymbols(subscriptions)) {
                const subscription = subscriptions[symbol];
                try {
                  await Promise.allSettled([subscription[key]?.(result, ...args), subscription.all?.(key, result, ...args)]);
                } catch {}
              }
            }
            resolve(result);
          } catch (error) {
            for (const symbol of Object.getOwnPropertySymbols(subscriptions)) {
              try {
                const subscription = subscriptions[symbol];
                await subscription?.catch?.(key, error, ...args);
              } catch {}
            }
            reject(error);
          }
        }
        if (key === INITIALIZE_KEY) {
          if (initialized) {
            reject(InitializeError);
            return;
          } else {
            initialized = true;
            queue.unshift(settle);
          }
        } else {
          if (isUndefined(config.initialize)) {
            initialized = true;
          }
          queue.push(settle);
        }
        if (!initialized || flushing) {
          return;
        }
        flushing = true;
        dequeue();
      });
    };
  }
  const properties = Object.keys(config).reduce((accumulator, key) => ({
    ...accumulator,
    [key]: isFunction(config[key]) ? createMethod(key) : config[key]
  }), {});
  return {
    ...properties,
    __SUBSCRIPTIONS__: subscriptions,
    get enabled() {
      return enabled;
    },
    get flushing() {
      return flushing;
    },
    get initialized() {
      return initialized;
    },
    disable() {
      enabled = false;
    },
    enable() {
      enabled = true;
    },
    subscribe(subscription) {
      const key = Symbol(v4());
      subscriptions[key] = subscription;
      return function unsubscribe() {
        if (flushing) {
          queue.push(async () => {
            delete subscriptions[key];
          });
          return;
        }
        delete subscriptions[key];
      };
    }
  };
}

export { createEmitter as c };
