import Events from 'modules/Analytics/constants/events';
import getStore from 'state/factory';
import whenPopulated from 'utils/whenPopulated';
import { getProfileId } from 'state/Session/selectors';
import { isPrivacyOptOut } from './privacyOptOut';
import { loadScript } from '@iheartradio/web.utilities';
import { TrackerConfig, waitForGlobal } from '@iheartradio/web.signal';
import type { ComScoreBeaconPayload } from 'global';
import type { EventTypeMap } from './types';

const comScoreTracker = (
  id: string,
  enabled: boolean,
): TrackerConfig<EventTypeMap> => {
  return {
    enabled,

    name: 'ComScore',

    initialize: async () => {
      await loadScript(`https://sb.scorecardresearch.com/cs/${id}/beacon.js`, {
        async: true,
      });
      await waitForGlobal('COMSCORE');
    },

    events: {
      [Events.PageView]: async () => {
        const comScoreMessage: ComScoreBeaconPayload = {
          c1: '2', // This is a magic string...
          c2: id,
        };
        const store = getStore();
        const profileId = await whenPopulated<ReturnType<typeof getProfileId>>(
          store,
          getProfileId,
        );

        const privacyOptOut = isPrivacyOptOut(store.getState());

        comScoreMessage.cs_ucfr = privacyOptOut ? '0' : '1';
        comScoreMessage.cs_xi = String(profileId);

        comScoreMessage.options = {
          enableFirstPartyCookie: true,
          bypassUserConsentRequirementFor1PCookie: !privacyOptOut,
        };

        window.COMSCORE.beacon({
          ...comScoreMessage,
        });
      },
    },
  };
};

export default comScoreTracker;
